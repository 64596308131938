// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    user_data: {}
  },
  mutations: {
    set_user_data(state, data) {
      state.user_data = data;
    }
  },
  actions: {
    update_user_data({ commit }, data) {
      commit('set_user_data', data);
    }
  },
  getters: {
    get_user_data: state => state.user_data
  }
});
