<template>
	<div class="navbar">
		<div style="font-size: 1.5rem; font-weight: 520; width: 150px; letter-spacing: 0.1rem"
			@click="go_to_route('/')">
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"
				style="margin-top: 10px;">
				<rect width="30" height="30" rx="5" fill="#000" />
				<path
					d="M18.5379 11.2468C18.455 10.4715 18.1058 9.86778 17.4903 9.43572C16.8807 9.00367 16.0876 8.78764 15.111 8.78764C14.4245 8.78764 13.8356 8.89122 13.3443 9.09837C12.8531 9.30552 12.4773 9.58665 12.2169 9.94176C11.9564 10.2969 11.8233 10.7023 11.8173 11.158C11.8173 11.5368 11.9032 11.8653 12.0748 12.1435C12.2524 12.4216 12.4921 12.6584 12.7939 12.8537C13.0958 13.0431 13.4302 13.2029 13.7971 13.3331C14.1641 13.4633 14.534 13.5728 14.9068 13.6616L16.6114 14.0877C17.2979 14.2475 17.9579 14.4635 18.5911 14.7358C19.2303 15.008 19.8015 15.3513 20.3046 15.7656C20.8136 16.1799 21.216 16.68 21.512 17.266C21.8079 17.8519 21.9558 18.5385 21.9558 19.3256C21.9558 20.391 21.6836 21.3291 21.1391 22.1399C20.5946 22.9448 19.8074 23.5752 18.7776 24.0309C17.7537 24.4807 16.5137 24.7056 15.0578 24.7056C13.6432 24.7056 12.4151 24.4866 11.3735 24.0487C10.3377 23.6107 9.52687 22.9715 8.94093 22.131C8.36091 21.2906 8.04723 20.2667 7.99988 19.0593H11.2403C11.2876 19.6926 11.483 20.2193 11.8262 20.6396C12.1695 21.0598 12.6164 21.3735 13.1668 21.5806C13.7231 21.7878 14.3446 21.8913 15.0311 21.8913C15.7473 21.8913 16.3746 21.7848 16.9132 21.5717C17.4577 21.3527 17.8839 21.0509 18.1916 20.6662C18.4994 20.2756 18.6562 19.8198 18.6622 19.299C18.6562 18.8255 18.5172 18.4349 18.2449 18.1271C17.9727 17.8134 17.5909 17.553 17.0997 17.3459C16.6143 17.1328 16.0462 16.9434 15.3951 16.7777L13.3266 16.245C11.8292 15.8603 10.6455 15.2773 9.77545 14.4961C8.91134 13.7089 8.47928 12.6643 8.47928 11.3622C8.47928 10.291 8.76929 9.35286 9.34931 8.54794C9.93525 7.74302 10.7313 7.11861 11.7374 6.67472C12.7436 6.22491 13.8829 6 15.1554 6C16.4457 6 17.5761 6.22491 18.5468 6.67472C19.5233 7.11861 20.2898 7.7371 20.8461 8.53018C21.4025 9.31735 21.6895 10.2229 21.7073 11.2468H18.5379Z"
					fill="#F1FBF8" />
			</svg>
		</div>
		<nav>
			<MobileNavbarItem :items="menus" />
		</nav>
	</div>
</template>
<script>

import MobileNavbarItem from './nav_components/MobileNavItem.vue'
import axios from 'axios'

export default {
	name: 'NavBar',
	components: {
		MobileNavbarItem,
	},
	data() {
		return {
			window_width: window.innerWidth,
			mobile: window.innerWidth > 640,
			user_data: null,
			menus: [
				{ name: 'Home', url: "/" },
				{ name: 'Profile', url: "/profile" },
				{ name: 'Support', url: "/support" },
				{ name: 'Logout', url: "/logout" }
			]
		}
	},
	mounted() {
		this.checkIsActive()
		this.intervalId = setInterval(this.checkIsActive, 60000) 
	},
	beforeUnmount() {
		if (this.intervalId) {
			clearInterval(this.intervalId)
		}
	},
	methods: {
		go_to_route(new_route) {
			if (this.$router && new_route) {
				this.$router.push(new_route);
			} else {
				console.error("Err.");
			}
		},
		checkIsActive() {
			if (this.$route.path !== '/login') {
				axios.post('/isactive', {
					activity:"application",
					url: this.$route.path
				})
					.then(response => {
						// Handle the response if needed
						console.log('Is active check:', response.data)
					})
					.catch(error => {
						console.error('Error checking isactive:', error)
					})
			}
		},
	},
}

</script>

<style scoped>
.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: bg_color;
	height: 50px;
	max-width: 100%;
	letter-spacing: 0.05rem;
	padding-left: 5vw;
	padding-right: 5vw
}
</style>