import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './registerServiceWorker'
import posthogPlugin from "./plugins/posthog";

const app = createApp(App);
app.use(router);
app.use(store);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.type === 'OFFLINE_CONTENT') {
            alert(event.data.message);
        }
    });
}
app.use(posthogPlugin);
app.mount('#app');
