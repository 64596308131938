<template>
    <div class="menu-container" style="margin-left: auto;">
        <div @click="menu_toggle" class="menu_lines">
            <div :class="{ 'line': true, 'top-line': true, 'open': display_menu }" ></div>
            <div :class="{ 'line': true, 'middle-line': true, 'open': display_menu }"></div>
            <div :class="{ 'line': true, 'bottom-line': true, 'open': display_menu }"></div>
        </div>
        <div v-if="display_menu" class="navbar-mobile-item" >
            <div class="navbar-item" v-for="(item, index) in items" :key="index" @click="go_to_route(item.url)">
                <div>{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileNavbarItem',
    props: {
        items: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            display_menu: false,
        };
    },
    methods: {
        menu_toggle() {
            if(window.location.href.split('/').pop()!=="login"){
                this.display_menu = !this.display_menu;
                console.log(this.items)
            }
        },
        go_to_route(path) {
            console.log(this.items)
            if (this.$router && path) {
                this.$router.push(path);
            } else {
                console.log(this.items)
                console.error("Router instance not found or path undefined.");
            }
            this.menu_toggle()
        }
    },
}
</script>

<style scoped>
.menu-container {
    position: relative;
    width: 25px;
    
}

.menu_lines {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 25px;
    z-index: 1001;
}

.line {
    width: 24px;
    height: 4px;
    border-radius: 3px;
    position: absolute;
    left: 0;
    background-color: black;
    transition: all 0.2s ease;
}

.top-line {
    top: 3px;
}

.middle-line {
    top: 11px;
}

.bottom-line {
    top: 19px;
}

.open.top-line {
    transform: rotate(45deg);
    transform-origin: top left;
}

.open.middle-line {
    width: 0%;
    opacity: 22;
}

.open.bottom-line {
    transform: rotate(-45deg);
    transform-origin: bottom left;
}

.navbar-mobile-item {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 52px;
    padding-left: 5vw;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: white;
    opacity: 1;
    z-index: 1001;
    padding-bottom: 25px;
}

.navbar-item {
    padding-top: 24px;
    padding-left: 4px;
    cursor: pointer;
    text-align: left;
    width: fit-content;
    font-weight: 550;
    font-size: 1rem;
    display: flex;
}

</style>
