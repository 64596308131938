<template>
  <div id="app">
    <NavBar />
    <router-view/>
  </div>
</template>

<script>
import NavBar from "./components/navbar/NavBar.vue"
export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
/* Global styles */
body{
  margin: 0;
  padding: 0;
}
#app{
  margin: 0;
  padding: 0;
}
</style>
