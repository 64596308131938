import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        name: 'LoginPage',
        path: '/login',
        component: () => import('./components/pages/login/LoginPage.vue')
    },
    {
        name: 'HomePage',
        path: '/',
        component: () => import('./components/pages/inspections/InspectionListPage.vue')
    },
    {
        name: 'CreateInspectionPage',
        path: '/create/inspection',
        component: () => import('./components/pages/inspections/CreateInspection.vue'),
        props: true  
    },
    {
        name: 'ManageInspectionPage',
        path: '/manage/inspection/:inspection_id',
        component: () => import('./components/pages/inspections/ManageInspection.vue'),
        props: true  
    },
    {
        name: 'PlanListPage',
        path: '/plans/:inspection_id',
        component: () => import('./components/pages/plans/PlanListPage.vue')
    },
    {
        name: 'AddPlanPage',
        path: '/create/plan/:inspection_id',
        component: () => import('./components/pages/plans/AddPlan.vue'),
        props: true  
    },
    {
        name: 'ReportsPage',
        path: '/reports/:inspection_id',
        component: () => import('./components/pages/reports/ReportsPage.vue'),
        props: true  
    },
    {
        name: 'ProfilePage',
        path: '/profile',
        component: () => import('./components/pages/inspections/InspectionListPage.vue'),
        props: true  
    },
    {
        name: 'UsersPage',
        path: '/users',
        component: () => import('./components/pages/users/UsersPage.vue'),
        props: true  
    },
    {
        name: 'AddUserPage',
        path: '/adduser',
        component: () => import('./components/pages/users/AddUser.vue'),
        props: true  
    },
    {
        name: 'BillingsPage',
        path: '/billing',
        component: () => import('./components/pages/inspections/InspectionListPage.vue'),
        props: true  
    },
    {
        name: 'SupportPage',
        path: '/support',
        component: () => import('./components/pages/inspections/InspectionListPage.vue'),
        props: true  
    },
    {
        name: 'LogoutPage',
        path: '/logout',
        component: () => import('./components/pages/logout/LogoutPage.vue'),
        props: true  
    },
    {
        name: 'WebGlPage',
        path: '/webgl',
        component: () => import('./components/pages/webgl/WebGl.vue'),
        props: true  
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router;